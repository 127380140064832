@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Concert+One&family=Neonderthaw&display=swap');

.App-header {
  position: fixed;
  display: flex;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}
.App-footer {
  background-color: #164a6d;
  padding: 30px 40px;
  color: #fff;
  font-family: 'Concert One';
  font-size: 18px;
  text-align: center;
}
.App-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.App-social li {
  padding: 5px 10px 0;
}

.App-social li img {
  transform: scale(1.15);
  transition: transform 0.25s ease-in-out 0s;
}
.App-social li img:hover{
  transform: scale(1.40);
}
.App-header .links {
  font-family: 'Concert One';
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgba(37, 37, 37, 0.637);
  font-size: 20px;
  display: block;
  position: relative;
  z-index: 10px;
  padding: 0 2em;
  margin: 0 -2em;
}

.App-header .links a {
  display: inline-block;
  z-index: -1px;
  padding: 0 2em;
  margin: 0 -2em;
}

.App-top {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 80px 10px 0px 10px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #3e74bb;
  text-transform: uppercase;
  background-image: url('./media/background.png');
  background-size: cover;
}

.App-date {
  margin-top: 80px;
  font-family: 'Concert One';
  letter-spacing: 2px;
  font-size: 30px;
}

.MintStatus {
  color:#a81f1f;
  font-family: 'Concert One';
  font-size: larger;
  margin-bottom: 60px;
}

.increment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.increment .v1 {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.increment .v2 {
  padding: 2px;
  padding-inline: 30px;
  background-color: rgb(228, 228, 228);
  color: rgb(49, 49, 49);
  font-family: 'sans';
  font-size: 20px;
  font-weight: bold;
}

.increment .incrementbut {
  padding: 10px;
  padding-inline: 20px;
  background-color: rgb(126, 126, 126);
  border-radius: 10px;
}

.increment .incrementbut:hover {

  background-color: rgb(94, 94, 94);
}

.App-main {
  font-family: Helvetica;
  color: #fff;
}
.flex-vertical-center { align-items: center; }

h2 {
  width: 100%;
  margin-bottom: 10px;
}

.Sign {
  background-color: #5f8069;
}

.Clinks {
  display: inline-flex;
}

.MintButton {
  margin-bottom: 10px;
  display: inline-block;
  padding: 5px;
  padding-bottom: 20px;
  padding-inline: 40px;
  background: #1976d2;
  font-family: 'Concert One';
  font-size: 35px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease-in-out 0s;
}

.MintButton:hover {
  background-color: #334155;
}

.welcome {
  width: 400px;
}

.welcomesign {
  width: 55vmax;
}

.App-logo {
  margin-bottom: 70px;
  max-width: 80%;
}

.App-count {
  font-family: 'Concert One';
  font-size: 72px;
  font-weight: bold;
  padding: 20px 0px 0px 0px;
  letter-spacing: 8px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.74);
  text-align: center;
}

.App-title {
  font-size: 24px;
  line-height: 48px;
  font-weight: bold;
  letter-spacing: 10px;
  margin-bottom: 60px;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 20px;
  width: 100%;
}

.App-button {
  padding: 10px 45px;
  font-size: 24px;
  line-height: 48px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #352951;
  background: #7de9ab;
  border-radius: 100px;
  text-transform: uppercase;
}

.App-form {
  display: inline-flex;
  color: #191919;
}

.App-input {
  padding: 10px;
  margin-right: 15px;
  text-align: center;
  font-size: 30px;
  border-radius: 100%;
}

.App-input::-webkit-outer-spin-button,
.App-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App-input[type='number'] {
  -moz-appearance: textfield;
}

.App-mint {
  color: #294951;
  background: #7de9ab;
}

.App-success {
  margin-top: 50px;
  font-size: 20px;
}


.App-link {
  text-decoration: underline;
}
.Page-break {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 100px 0;
  position: relative;
}

.Page-break:before,
.Page-break:after {
  content: '';
  display: block;
  height: 2px;
  width: 25%;
  background: #ffffff;
}

.Grid-inner {
  font-family: 'Concert One';
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}

.Grid img {
  width: 100%;
}

.Roadmap {
  color: #ffffff;
  font-size: 50px;
  line-height: 2;
  text-align: center;
}

.Roadmap h3 {
  font-size: 48px;
  margin-bottom: 60px;
}

.Roadmap p {
  margin-bottom: 20px;
}

.rounded {
  border-radius: 5px;
  overflow: hidden;
}

.Team {
  font-family: 'Neonderthaw';
}

.Yellow-wrapper {
  font-family: 'Concert One';
  /* background: #a34141; */
  background: #568167;
  border-bottom: 10px solid #9ed5f0;
  padding: 25px 0 25px;
}

.Yellow-wrapper .grid {
  grid-template-rows: 1fr;
  grid-template-columns: 70% 30%;

}

.circle {
  height:95%;
  width: 75%;
  background-color: #145747;
  border-radius: 50%;
  margin-left: 40px;
  margin-top: 40px;
}

.Yellow-wrapper .grid .boxfront {
  grid-column: 1/3;
  grid-row: 1/3;
}

.Yellow-wrapper .grid .box1 {
  grid-column: 1/3;
  grid-row: 1/3;
  max-width: 70%;
  margin-left: 40px;
}

.Yellow-wrapper .grid .box2 {
  max-width:100%;
  grid-row: 2;
  grid-column: 1/3;
  padding-bottom: 8%;
  padding-left: 40%;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.685);
}

.Pink-wrapper {
  font-family: 'Concert One';
  background: #568167;
  border-top: 10px solid #145747;
  border-bottom: 10px solid #145747;
  padding: 100px 0 0px 0px;
  background-image: url('./media/golf.jpg');
  background-size:cover;
}

.Pink-wrapper .Container {
  overflow:hidden;
}

.Lilac-wrapper {
  background: #40687e;
  border-bottom: 10px solid #9ed5f0;
  padding-top: 20px;
  padding: 80px 0 140px;
}

@media (max-width: 450px) {
  .App-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 10px;
  }

  .App-count {
    position: relative;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 2.5%;
    font-size: 50px;
    letter-spacing: 4px;
  }

  .App-main .Container {
    display: flex;
    flex-direction: column;
    padding : 10px 10px 0px 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .App-main .Container .bot {
    align-items: center;
    justify-content: center;
  }

  .App-main .Container p {
    width: 100%;
  }

  .Pink-wrapper {
    padding: 60px 0px 0px 0px;
  }

  .App-social {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .welcome {
    width: 250px;
  }

  .MintButton {
    font-size: 25px;
    padding: 0px 20px 10px 20px
  }

  .Clinks {
    display: flex;
    flex-direction: column;
  }

  .Yellow-wrapper .grid .box1 {
    grid-column: 1/3;
    grid-row: 1/3;
    max-width: 90%;
    margin-left: 0;
  }
  
  .Yellow-wrapper .grid .box2 {
    max-width:100%;
    grid-row: 2;
    grid-column: 1/3;
    padding-bottom: 11%;
    padding-left: 65%;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.685);
    font-size: 30px;
  }
  .circle {
    height:90%;
    width: 89%;
    background-color: #145747;
    border-radius: 50%;
    margin-left: 5%;
    margin-top: 40px;
  }

  .Yellow-wrapper .box3 {
    font-size: 20px;
  }
  .Grid-inner {
    font-family: 'Concert One';
    grid-template-columns: 1fr;
  }
}

@media (min-width: 450px) {
  .Grid-inner {
    font-family: 'Concert One';
    grid-template-columns: fit-content(60%);
  }

}
@media (max-width: 768px) {
  .welcomesign {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .Page-break:before,
  .Page-break:after {
    width: 40%;
  }

  .Grid-inner {
    font-family: 'Concert One';
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }

  .Grid img {
    transition: transform 0.25s ease-in-out;
  }
  .Grid img:hover {
    transform: scale(1.05);
  }
}

@media (min-width: 1024px) {
  .App-social {
    justify-content: start;
  }
  .About {
    flex-direction: row;
  }
  .About img {
    margin: 0 72px 0 0;
    max-width: 420px;
  }

  .About img,
  .Grid img {
    transition: transform 0.25s ease-in-out;
  }
  .About img:hover,
  .Grid img:hover {
    transform: scale(1.05);
  }
  .Grid-inner {
    font-family: 'Concert One';
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
  }
}
